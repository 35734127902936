const impressumContent = {
  section1: {
    title: `Description du site`,
    description: `Dropzik est une petite start-up qui offre aux musiciens tout ce dont ils
    ont besoin pour vendre, gérer et monétiser leur musique en ligne. Nous
    sommes une petite équipe internationale et passionnée de musique, et
    aimons à dire que notre but, c’est de vous débarrasser de la partie
    ennuyeuse du travail, pour que vous puissiez vous concentrez sur ce
    qui compte vraiment: faire de la musique !`,
  },
  section2: {
    title: `Montréal, Canada (Siège Social)`,
    description: `Dropzik Digital INC.
    8815 ave. du Parc, Suite 402,
    Montréal, Québec, H2N 1Y7
    Canada`,
    email: 'Sc@dropzik.com',
    description2: `Directeur Général : Sid Ali Baziz`,
    description3: `Numéro de TVA :`
  }
}

export default impressumContent;