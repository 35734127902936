import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import impressumContent from '../config/contents/impressum';

const ConditionsGeneralesPage: React.FC<PageProps<any>> = ({ data }) => {
  const { pageImage, site } = data;
  const { title, path } = site?.siteMetadata?.pages?.impressum;

  return (
    <Layout>
      <SEO title={title} path={path} image={pageImage?.childImageSharp.fluid.src} />

      <div className="d-block bg-white pt-5">
        <div className="container pt-5">
          <div className="container__row row py-3 py-md-5">

            <div className="d-block">
              <h2 className="title mt-0 mb-3">{impressumContent.section1.title}</h2>
              <p>{impressumContent.section1.description}</p>
            </div>

            <div className="d-block">
              <h2 className="title mt-0 mb-3">{impressumContent.section2.title}</h2>
              <p>{impressumContent.section2.description}</p>
              <p>{impressumContent.section2.description2}</p>
              <p>{impressumContent.section2.description3}</p>
            </div>

          </div>
        </div>
      </div>


    </Layout>
  );
}

export const query = graphql`
  query impressumQuery {
    site {
      siteMetadata {
        lang
        pages {
          impressum {
            title
            path
          }
        }
      }
    }
    pageImage: file(relativePath: { eq: "dropzik-digital-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default ConditionsGeneralesPage;
